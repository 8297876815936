exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-portfolio-festiwal-kolorow-tsx": () => import("./../../../src/pages/portfolio/festiwal-kolorow.tsx" /* webpackChunkName: "component---src-pages-portfolio-festiwal-kolorow-tsx" */),
  "component---src-pages-portfolio-kartki-bozonarodzeniowe-tsx": () => import("./../../../src/pages/portfolio/kartki/bozonarodzeniowe.tsx" /* webpackChunkName: "component---src-pages-portfolio-kartki-bozonarodzeniowe-tsx" */),
  "component---src-pages-portfolio-kartki-index-tsx": () => import("./../../../src/pages/portfolio/kartki/index.tsx" /* webpackChunkName: "component---src-pages-portfolio-kartki-index-tsx" */),
  "component---src-pages-portfolio-kartki-pocztowki-tsx": () => import("./../../../src/pages/portfolio/kartki/pocztowki.tsx" /* webpackChunkName: "component---src-pages-portfolio-kartki-pocztowki-tsx" */),
  "component---src-pages-portfolio-kartki-urodzinowe-tsx": () => import("./../../../src/pages/portfolio/kartki/urodzinowe.tsx" /* webpackChunkName: "component---src-pages-portfolio-kartki-urodzinowe-tsx" */),
  "component---src-pages-portfolio-kartki-wielkanocne-tsx": () => import("./../../../src/pages/portfolio/kartki/wielkanocne.tsx" /* webpackChunkName: "component---src-pages-portfolio-kartki-wielkanocne-tsx" */),
  "component---src-pages-portfolio-klocki-braillea-tsx": () => import("./../../../src/pages/portfolio/klocki-braillea.tsx" /* webpackChunkName: "component---src-pages-portfolio-klocki-braillea-tsx" */),
  "component---src-pages-portfolio-milin-tsx": () => import("./../../../src/pages/portfolio/milin.tsx" /* webpackChunkName: "component---src-pages-portfolio-milin-tsx" */),
  "component---src-pages-portfolio-modulowe-domki-dla-lalek-tsx": () => import("./../../../src/pages/portfolio/modulowe-domki-dla-lalek.tsx" /* webpackChunkName: "component---src-pages-portfolio-modulowe-domki-dla-lalek-tsx" */),
  "component---src-pages-portfolio-przeplotki-tsx": () => import("./../../../src/pages/portfolio/przeplotki.tsx" /* webpackChunkName: "component---src-pages-portfolio-przeplotki-tsx" */),
  "component---src-pages-portfolio-tablice-kredowe-tsx": () => import("./../../../src/pages/portfolio/tablice-kredowe.tsx" /* webpackChunkName: "component---src-pages-portfolio-tablice-kredowe-tsx" */),
  "component---src-pages-portfolio-tabliczki-kredowe-tsx": () => import("./../../../src/pages/portfolio/tabliczki-kredowe.tsx" /* webpackChunkName: "component---src-pages-portfolio-tabliczki-kredowe-tsx" */),
  "component---src-pages-portfolio-wiersze-dla-dzieci-tsx": () => import("./../../../src/pages/portfolio/wiersze-dla-dzieci.tsx" /* webpackChunkName: "component---src-pages-portfolio-wiersze-dla-dzieci-tsx" */),
  "component---src-pages-portfolio-zakladki-tsx": () => import("./../../../src/pages/portfolio/zakladki.tsx" /* webpackChunkName: "component---src-pages-portfolio-zakladki-tsx" */)
}

